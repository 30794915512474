import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import { map as _map, reduce as _reduce, noop as _noop } from "lodash";

import {
  janeCategories,
  janeCategoryLookupBySlug,
} from "@col-care/common/providers/iheartjane/jane-embed";

import * as styles from "../styles/sitemap.module.css";

const Sitemap = () => {
  const state = useSelector((state) => state);

  const sitemap = state?.hydrate?.databagData?.sitemap;

  if (!sitemap) {
    // console.error('No sitemap data was found!')
    return null;
  }

  const allSitePage = sitemap.allContentstackSitePage
    ? Object.keys(sitemap.allContentstackSitePage)
        .reduce((a, v) => {
          a.push(sitemap.allContentstackSitePage[v]);
          return a;
        }, [])
        .sort((a, b) => {
          return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
        })
    : null;

  const allPartnerLocation = sitemap.allContentstackPartnerLocation
    ? Object.keys(sitemap.allContentstackPartnerLocation)
        .reduce((a, v) => {
          a.push(sitemap.allContentstackPartnerLocation[v]);
          return a;
        }, [])
        .sort((a, b) => {
          return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
        })
    : null;

  const allProductCategory = sitemap.productCategoryPage
    ? Object.keys(sitemap.productCategoryPage)
        .reduce((a, v) => {
          a.push(sitemap.productCategoryPage[v]);
          return a;
        }, [])
        .sort((a, b) => {
          return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
        })
    : null;

  const allProductDetail = sitemap.productDetailPage
    ? Object.keys(sitemap.productDetailPage)
        .reduce((a, v) => {
          a.push(sitemap.productDetailPage[v]);
          return a;
        }, [])
        .sort((a, b) => {
          return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
        })
    : null;

  return (
    <div id={styles.sitemap_container}>
      <div className={styles.sitemap_header_container}>
        <h1>Sitemap</h1>
      </div>
      <div id={styles.sitemap}>
        <h3>Pages</h3>
        <div className={styles.sitemap_container}>
          {formatSitemapLinks(allSitePage)}
        </div>

        <h3>Locations</h3>
        <div className={styles.sitemap_container}>
          {formatSitemapLinks(allPartnerLocation)}
        </div>

        {/* <h3>Categories</h3>
        <div className={styles.sitemap_container}>
          {formatSitemapLinks(allProductCategory)}
        </div> */}

        <h3>Products</h3>
        <div className={styles.sitemap_product_container}>
          {formatProductsLinks(allProductDetail)}
        </div>
      </div>
    </div>
  );
};

Sitemap.displayName = "Sitemap";

export default Sitemap;

const formatSitemapLinks = (sitemap, markup = []) => {
  for (const page of sitemap) {
    markup.push(
      <div className={styles.sitemap_item} key={page.url}>
        <Link key={page.url} to={page.url}>
          {page.title}
        </Link>
      </div>
    );
  }

  return markup;
};

const formatProductsLinks = (products) => {
  let markup = [];

  const productsByCategory = _reduce(
    janeCategories,
    (acc, val) => {
      acc[val] = janeCategoryLookupBySlug[val];
      acc[val].itemsList = [];
      acc[val].itemsLookup = {};
      return acc;
    },
    {}
  );

  _map(products, (val) => {
    const segments = val.url.split("/");

    if (!productsByCategory[segments[2]]) {
      productsByCategory[segments[2]] = {};
    }

    productsByCategory[segments[2]].itemsList.push(val);
    productsByCategory[segments[2]].itemsLookup[val.url] = val;
  });

  _map(janeCategories, (val) => {
    const thisMarkup = [];

    productsByCategory[val].itemsList.length > 0 &&
      markup.push(
        <div className={styles.sitemap_subheading}>
          {productsByCategory[val].name}
        </div>
      );

    markup.push(
      <div className={styles.sitemap_container}>
        {(() => {
          for (const page of productsByCategory[val].itemsList) {
            thisMarkup.push(
              <div className={styles.sitemap_item} key={page.url}>
                <Link key={page.url} to={page.url}>
                  {page.title}
                </Link>
              </div>
            );
          }
          return thisMarkup;
        })()}
      </div>
    );
  });

  return markup;
};
